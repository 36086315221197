<template>
  <div class="realEstatePanel">
    <div class="mt-2">
      <!-- Header: New Electricity -->
      <div class="d-flex">
        <feather-icon icon="SendIcon" size="19"/>
        <h4 class="mb-0 ml-50">
          {{ msg('New provider') }}
        </h4>
      </div>

      <!-- Form: Electricity Info -->
      <b-row class="mt-1">

        <b-col cols="12">
          <b-row>
            <b-col cols="4" align-self="center">
              <b-img :src="electricity.imageUri" fluid thumbnail style="max-width: 240px; max-height: 300px"/>
            </b-col>
            <b-col cols="8">
              <b-row>
                <b-col cols="12">
                  <b-form-group :label="msg('Provider')">
                    <b-input v-model="electricity.provider"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :label="msg('Operator')">
                    <b-input v-model="electricity.operator"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <!-- WiFi Name -->
        <b-col cols="6">
          <b-form-group :label="msg('Electricity box')">
            <v-select
                v-model="electricity.electroBox"
                label="text"
                :reduce="(status) => status.value"
                :options="[
        { value: 'manual', text: msg('Manual') },
        { value: 'smartmeter', text: msg('Smartmeter') }
      ]"
                placeholder="Select an option"
            />
          </b-form-group>
        </b-col>


        <!-- Phone Number -->
        <b-col cols="6">
          <b-form-group :label="msg('Support phone')">
            <b-form-input v-model="electricity.phoneSupport" type="tel"/>
          </b-form-group>
        </b-col>

        <!-- KW Usage -->
        <b-col cols="6">
          <b-form-group :label="msg('KW Usage')">
            <b-form-input v-model="electricity.kwUsage" type="number"/>
          </b-form-group>
        </b-col>
        <!-- partialAmount  -->
        <b-col cols="6">
          <b-form-group :label="msg('Partial amount')">
            <b-form-input v-model="electricity.partialAmount" type="number"/>
          </b-form-group>
        </b-col>


        <!-- Electricity Password -->
        <b-col cols="6">
          <b-form-group :label="msg('Electricity Password')">
            <b-form-input v-model="electricity.electricityPassword"/>
          </b-form-group>
        </b-col>

        <!-- Last Charge -->
        <b-col cols="6">
          <b-form-group :label="msg('Registered')">
            <b-form-datepicker v-model="electricity.registered"/>
          </b-form-group>
        </b-col>


        <!-- Account -->
        <b-col cols="6">
          <b-form-group :label="msg('Account')">
            <b-form-input v-model="electricity.account"/>
          </b-form-group>
        </b-col>

        <!-- Password -->
        <b-col cols="6">
          <b-form-group :label="msg('Password')">
            <b-form-input v-model="electricity.password"/>
          </b-form-group>
        </b-col>

        <!-- Account -->
        <b-col cols="6">
          <b-form-group :label="msg('Counter')">
            <b-form-input v-model="electricity.counter"/>
          </b-form-group>
        </b-col>

        <!-- Password -->
        <b-col cols="6">
          <b-form-group :label="msg('Type')">
            <b-form-input v-model="electricity.electricityType"/>
          </b-form-group>
        </b-col>


        <!-- Status -->
        <b-col cols="6">
          <b-form-group :label="msg('Status')">
            <v-select
                v-model="electricity.status"
                :options="electricityStatus"
                class="w-100"
                label="text"
                :reduce="(status) => status.value"
            />
          </b-form-group>
        </b-col>

        <!-- Login Link -->
        <b-col cols="6">
          <b-form-group :label="msg('Link')">
            <b-form-input v-model="electricity.link"/>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group :label="msg('Image Uri')">
            <b-input v-model="electricity.imageUri"/>
          </b-form-group>
        </b-col>

        <!-- Branch ID -->
        <b-col cols="12">
          <b-form-group :label="msg('Branch')">
            <v-select
                v-model="electricity.buid"
                :options="allBranches"
                class="w-100"
                label="name"
                :reduce="(branch) => branch.id"
                :clearable="false"

            />
          </b-form-group>
        </b-col>


        <b-col cols="12">
          <b-form-group :label="msg('Real estate')">
            <real-estate-picker multiple v-model="electricity.propertyIds"/>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Save Button -->
      <div v-if="electricity.buid!=null&& electricity.provider!=null" style="float: right"
           class="justify-content-center mt-2">
        <b-button variant="outline-primary" class="btn-dialog justify-content-center" @click="createElectricity">
          {{ msg('Save') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";
import {msg} from "@babel/core/lib/config/validation/option-assertions";

export default {
  name: 'electricityPanel',
  components: {RealEstatePicker},
  data() {
    return {
      loaded: false,
      contractId: null,
      electricity: {},
      clientId: null,
      contractList: [],
      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },


  computed: {
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('electricity', ['allElectricityStatus']),

    electricityStatus() {
      let $this = this;
      return this.allElectricityStatus.map(status => ({
        value: status.value,
        text: $this.msg(status.text)
      }))
    },

    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  created() {
    let $this = this;

    this.$root.$on('crm::submitElectricity', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitElectricity', this.listeners.submit)
  },
  methods: {
    msg,
    ...mapActions('electricity', ['getAllElectricitys', 'copySuccess', 'addElectricity']),
    ...mapActions('realEstateContracts', ['getAllByClients']),

    formatDate(date) {
      return date ? this.$moment(date, "YYYY-MM-DD").format("DD.MM.YYYY") : "No date";
    },

    createElectricity() {
      let $this = this

      this.addElectricity({electricity: this.electricity})
          .then(
              (id) => {

                $this.$emit('submitted', {status: true, server: id, id})

              },
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
    },

    validate(field) {
      return this.$v.electricity[field] != null && this.$v.electricity[field].$error === true
    }
    ,
    submit() {
      return this.createElectricity()
    },

  },

}
</script>
<style scoped>

.dark-layout #realEstate-description {
  background-color: rgba(255, 255, 255, 0.66);
  color: #000000;
}

.quill-container #realEstate-description {

  min-height: 400px;
}
</style>


